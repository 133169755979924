import {PartnersListingSearch, PartnersManagementListing, UserInfo} from 'common/types';
import {apiMethod, APIService} from 'services/APIService';
import {PartnersChipsCounters} from "../../store/partners/reducers/chipsCounters";

export interface FetchAgenciesParams {
  phone: string;
}

export class PartnersAPI extends APIService {
  findPartner = apiMethod<UserInfo>(
    (params: FetchAgenciesParams) =>
      this.fetcher.request({
        method: 'get',
        url: 'api/partners/find/',
        params,
      })
  );

  approveRequestPartner = apiMethod((rieltorId: number) =>
    this.fetcher.request({
      method: 'put',
      url: `/api/partners/request/${rieltorId}/`,
    })
  );

  cancelRequestPartner = apiMethod((rieltorId: number) =>
    this.fetcher.request({
      method: 'delete',
      url: `/api/partners/request/${rieltorId}/`,
    })
  );

  fetchPartners = apiMethod<{ data: PartnersManagementListing }>((params: Partial<PartnersListingSearch>) =>
    this.fetcher.request({
      url: '/api/partners/list/',
      method: 'get',
      params,
    })
  );

  approvePartner = apiMethod((rieltorId: number) =>
    this.fetcher.request({
      method: 'put',
      url: `/api/partners/${rieltorId}/`,
    })
  );

  cancelPartner = apiMethod((userId: number) =>
    this.fetcher.request({
      method: 'delete',
      url: `/api/partners/${userId}/`,
    })
  );

  getChipsCounters = apiMethod<PartnersChipsCounters>((params: Partial<PartnersListingSearch>) =>
    this.fetcher.request({
      method: 'GET',
      url: '/api/partners/chips-counters/',
      params,
    })
  );
}
